import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import qs from 'qs';
// import { useNavigate } from 'react-router-dom';
import logo from 'assets/img/logo.png';

// import Header from "../../components/Header";
import useStyles from './style';
import Header from "components/header";
import { Button, Flex, Text } from "@chakra-ui/react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Footer from "components/footerUser";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, InputAdornment, InputLabel } from "@mui/material";

// import Button from '../../components/Button';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Signup = () => {
  // const navigate = useNavigate();
  const [params, setParams] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(0);
  const styles = useStyles();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRegister = () => {
    axios.post(`https://api.personatalenta.id/user/register`, qs.stringify({ ...params }), { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
      .then((res) => {
        if (res.data.message === "Pendaftaran berhasil") {
          setOpen(true)
        }
      }).catch(err => {
        setActive(0);
        setError(err.response.data.message || err.response.data)
      })
  };

  const onChangeInput = e => {
    const tmpValue = {};
    if (e.target.id === 'username') {
      tmpValue.name = e.target.value
      tmpValue[e.target.id] = e.target.value;
      setParams({ ...params, ...tmpValue });
    } else if (e.target.id === 'password') {
      tmpValue.password1 = e.target.value;
      tmpValue.password2 = e.target.value;
      setParams({ ...params, ...tmpValue });
    } else {
      tmpValue[e.target.id] = e.target.value;
      setParams({ ...params, ...tmpValue });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    if (active === 0) {
      if (!params.username) {
        setError('Username is required')
      } else if (!params.email) {
        setError('Email is required')
      } else if (!params.password1 || !params.password2) {
        setError('Password is Required')
      } else {
        setError('')
        setActive(active + 1)
      }
    } else if (active === 1) {
      if (!params.birthDate) {
        setError('Birth date is required');
      } else if (!params.birthTime) {
        setError('Birth time is required')
      } else if (!params.gender) {
        setError('Gender is required')
      } else {
        setError('')
        handleRegister();
      }
    }
  }

  return (
    <>
      <div className={styles.app}>
        <Header />
        <div className={styles.containerListItem}>
          <div className={styles.containerBackground}>
            <div className={styles.containerInput}>
              <div className="list">
                {error && (
                  <Flex bgColor="red" borderRadius={10} px={5} my={5}>
                    <Text color="white">{error}</Text>
                  </Flex>
                )}
                {active === 0 ? (
                  <Flex flexDirection={{ sm: "column", md: "row" }} gap={5}>
                    <Flex flex={1} flexDirection="column">
                      <img src={logo} className={styles.logo} alt="logo" />
                      <h1 style={{fontSize: 20}}>Buat akun Persona</h1>
                      <p style={{fontSize: 16}}>Silahkan masukkan username, email dan password</p>
                    </Flex>
                    <Flex flex={1} flexDirection="column" gap={5}>
                      <Flex flex={10}>
                        <TextField
                          fullWidth
                          label="Username"
                          id="username"
                          defaultValue={params.username ?? ""}
                          variant="outlined"
                          size="medium"
                          onChange={onChangeInput}
                          placeholder="minimal 6 huruf"
                        />
                      </Flex>
                      <Flex flex={10}>
                        <TextField
                          fullWidth
                          label="Email"
                          id="email"
                          defaultValue={params.email ?? ""}
                          variant="outlined"
                          size="medium"
                          type="email"
                          placeholder="email ini untuk verifikasi account"
                          onChange={onChangeInput}
                        />
                      </Flex>
                      <Flex flex={10}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="password">Password</InputLabel>
                          <OutlinedInput
                            label="Password"
                            fullWidth
                            id="password"
                            defaultValue={params.password ?? ""}
                            variant="outlined"
                            size="medium"
                            type={showPassword ? "text" : "password"}
                            placeholder="harus mempunyai huruf dan angka"
                            onChange={onChangeInput}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Flex>
                    </Flex>
                  </Flex>
                ) : (
                  <Flex flexDirection={{ sm: "column", md: "row" }} gap={5}>
                    <Flex flex={1} flexDirection="column">
                      <img src={logo} className={styles.logo} alt="logo" />
                      <h1 style={{fontSize: 20}}>Informasi Dasar</h1>
                      <p style={{fontSize: 16, marginBottom: 0}}>Masukkan tanggal dan jam lahir serta pilih gender anda</p>
                    </Flex>
                    <Flex flex={1} flexDirection="column" gap={5}>
                      <Flex flex={10}>
                        <TextField
                          label="Birth Date"
                          fullWidth
                          id="birthDate"
                          defaultValue={params.dateBirth ?? ""}
                          variant="outlined"
                          type="date"
                          size="medium"
                          onChange={onChangeInput}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Flex>
                    {/* </Flex>
                    <Flex flexDirection={{ sm: 'column', md: "row" }}> */}
                      <Flex flex={10}>
                        <TextField
                          label="Birth Time"
                          fullWidth
                          id="birthTime"
                          defaultValue={params.timeBirth ?? ""}
                          variant="outlined"
                          type="time"
                          size="medium"
                          onChange={onChangeInput}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Flex>
                    {/* </Flex>
                    <Flex flexDirection={{ sm: 'column', md: "row" }}> */}
                      <Flex flex={10}>
                        <RadioGroup
                          row
                          id="gender"
                          onChange={onChangeInput}
                          value={params?.gender ? Number(params.gender) : null}
                        >
                          <FormControlLabel value={1} control={<Radio id="gender" />} label="Female" />
                          <FormControlLabel value={0} control={<Radio id="gender" />} label="Male" />
                        </RadioGroup>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </div>
            </div>
            <div className={styles.containerButton}>
              <Button
                borderWidth={1}
                bg={"green"}
                color='white'
                fontSize='sm'
                fontWeight='bold'
                borderRadius='5px'
                px='25px'
                py='5px'
                onClick={() => handleNext()}
              >
                {active !== 1 ? "Next" : "Sign Up"}
              </Button>
            </div>
          </div>
        </div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Pendaftaran berhasil
          </DialogTitle>
          <DialogContent dividers>
            <Flex flexDirection="row" wrap="wrap" maxWidth="100%" gap={8} alignItems="center" justifyContent="center">
              <Text>Terima kasih telah mendaftar. Langkah terakhir yang perlu Anda lakukan adalah memverifikasi email Anda. Jika Anda tidak menemukan email verifikasi di kotak masuk, silakan periksa folder spam atau promosi.</Text>
            </Flex>
          </DialogContent>
          <DialogActions>
            <Button
              borderWidth={1}
              // borderColor="#1877f2"
              bg={"green"}
              color='white'
              fontSize='sm'
              fontWeight='bold'
              borderRadius='5px'
              px='10px'
              py='5px'
              onClick={() => window.location.assign('/login')}
            >
              OK
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
      <Footer />
    </>
  )
}

export default Signup
