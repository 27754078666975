import React, { useEffect, useState } from 'react';
import { io } from"socket.io-client";
import axios from 'axios';
import qs from 'qs';
import QRCode from "react-qr-code";
import TextField from '@mui/material/TextField';
import useStyles from './style';
import { Button, Flex, Text } from '@chakra-ui/react';
import { Divider } from '@mui/material';

const PaymentMethod = props => {
  const {productId, user, type, service} = props;
  const [isConnected, setIsConnected] = useState(false);
  const [productType, setProductType] = useState("");
  const [selectedMethod, setSelectedMethod] = useState()
  const [selectedType, setSelectedType] = useState({})
  const [activeType, setActiveType] = useState();
  const [activeMethod, setActiveMethod] = useState();
  const [listPayment, setListPayment] = useState([]);
  const [trxEvents, setTrxEvents] = useState([]);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [qrString, setQrString] = useState("");
  const [paymentResult, setPaymentResult] = useState();
  const styles = useStyles();

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`https://api.personatalenta.id/payment/method/list`, {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode"}})
    .then(res => {
      setListPayment(res.data.payment_types)
      // setUser(res.data.user)
      // console.log(res.data.user)
    }).catch(err => {
      if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
        // navigate('/');
      }
    })
  }, []);

  useEffect(() => {
    // Define the WebSocket endpoint
    const socket = io("https://api.personatalenta.id");
    let localUser = localStorage.getItem('user');
    if (localUser) {
      localUser = JSON.parse(localUser);
      function onConnect() {
        setIsConnected(true);
      }
  
      function onDisconnect() {
        setIsConnected(false);
      }
  
      function onTrxEvent(value) {
        setTrxEvents(previous => [...previous, value]);
        if (value === "COMPLETED" && type === "matching") {
          if (service?.includes("Partnership")) {
            window.location.assign(`/matching#partnership`);
          } else if (service?.includes("Romance")) {
            window.location.assign(`/matching#romance`);
          } else if (service?.includes("Friendship")) {
            window.location.assign(`/matching#friendship`);
          } else {
            window.location.assign(`/matching`);
          }
        } else if (value === "COMPLETED") {
          window.location.assign(`/dashboard/purchase-history#${type}`);
        }
      }
  
      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);
      socket.on(`TRX_USER_${localUser.id}_COMPLETE`, onTrxEvent);
  
      return () => {
        socket.off('connect', onConnect);
        socket.off('disconnect', onDisconnect);
        socket.off(`TRX_USER_${localUser.id}_COMPLETE`, onTrxEvent);
      };
  
    }
  }, [productType]); // Empty dependency array ensures this effect runs only once on component mount


  const handleSelectType = (type, index) => {
    setSelectedType(type)
    setActiveType(index)
  }

  const handleSelectMethod = (type, index) => {
    setSelectedMethod(type)
    setActiveMethod(index)
  }

  const handlePay = () => {
    const token = localStorage.getItem('token');
    let baseUrl = ""
    let params = {}
    if (selectedType.code === "ewallet") {
      baseUrl = `https://api.personatalenta.id/payment/ewallet`
      if (selectedMethod.code === "ID_OVO") {
        params = {product_id: parseInt(productId), payment_method_id: selectedMethod.id, mobile_number: `+${phoneNumber}`, is_has_user_id: props.isUser}
      } else {
        params = {product_id: parseInt(productId), payment_method_id: selectedMethod.id, is_has_user_id: props.isUser}
      }
    } else if (selectedType.code === "virtual_account") {
      baseUrl = `https://api.personatalenta.id/payment/va`
      params = {product_id: parseInt(productId), payment_method_id: selectedMethod.id, is_has_user_id: props.isUser}
    } else if (selectedType.code === "qr_code") {
      baseUrl = `https://api.personatalenta.id/payment/qris`
      params = {product_id: parseInt(productId), payment_method_id: selectedMethod.id, user_id: user.id, is_has_user_id: props.isUser}
    }
    axios.post(baseUrl, qs.stringify({...params}), {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "application/x-www-form-urlencoded"}})
    .then(res => {
      if (res.data.payment_result.actions) {
        window.open(res.data.payment_result.actions.mobile_web_checkout_url || res.data.payment_result.actions.desktop_web_checkout_url)
      }
      if (res.data.payment_result.qr_string) {
        setQrString(res.data.payment_result.qr_string)
        setProductType(res.data.product.type);
      }
      setPaymentResult(res.data)
      // setListPayment(res.data.payment_types)
      // setUser(res.data.user)
      // console.log(res.data.user)
    }).catch(err => {
      if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
        // navigate('/');
      }
    })
  }

  return (
    <Flex direction="column">
      <Flex
        borderBottomWidth={1}
        width="100%"
        direction={{sm: 'column', md: "row"}}
        gap={10}
        pt={5}
        pb={5}
        pr={10}
        pl={10}
      >
        <Flex flex={1} justifyContent="center" gap={5} alignItems="center" direction={{sm: 'column', md: 'row'}}>
          <Text fontWeight="bold">Pilih pembayaran</Text>
          <Flex display={{sm: 'flex', md: 'none'}}>
            {listPayment.map((type, index) => (
              <Flex
                padding={8}
                cursor="pointer"
                justifyContent="center"
                alignItems="center"
                borderWidth={1}
                borderRadius={10}
                borderColor={"#1A7431"}
                color={activeType === index ? "white" : "black"}
                bgColor={activeType === index ? "#1A7431" : "white"}
                onClick={() => handleSelectType(type, index)}
              >
                <Text>{type.name}</Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Divider orientation="vertical" />
        <Flex flex={1} justifyContent="center" gap={5} alignItems="center" direction={{sm: 'column', md: 'row'}}>
          <Text fontWeight="bold">Pilih metode pembayaran</Text>
          <Flex display={{sm: 'flex', md: 'none'}}>
            {(activeType !== null && selectedType?.payment_methods) && (
              <Flex direction="column" gap={5}>
                {selectedType.payment_methods.map((method, index) => (
                  <Flex
                  padding={5}
                  cursor="pointer"
                  borderWidth={1}
                  borderRadius={10}
                  borderColor={"#1A7431"}
                  color={activeMethod === index ? "white" : "black"}
                  bgColor={activeMethod === index ? "#1A7431" : "white"}
                  onClick={() => handleSelectMethod(method, index)}
                  >
                    <img src={method.logo} alt="method" width={100}/>
                  </Flex>
                ))}
                {(selectedMethod && !qrString) && (
                  <Flex>
                    <Button
                      borderWidth={1}
                      borderColor="#1A7431"
                      bgColor="#1A7431"
                      color='#fff'
                      fontSize='sm'
                      w="100%"
                      fontWeight='bold'
                      borderRadius='5px'
                      px='10px'
                      py='10px'
                      onClick={() => handlePay()}
                    >
                      {"Lanjut bayar"}
                    </Button>
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
        <Divider orientation="vertical" />
        <Flex flex={1} justifyContent="center" gap={5} alignItems="center" direction={{sm: 'column', md: 'row'}}>
          <Text fontWeight="bold">Bayar</Text>
          <Flex display={{sm: 'flex', md: 'none'}}>
            {qrString ? (
              <Flex direction="column" gap={5}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={qrString}
                  viewBox={`0 0 256 256`}
                />
                <Text textAlign="center">Rp {new Intl.NumberFormat('id-ID').format(paymentResult?.payment_result.amount)}</Text>
                <Button
                  borderWidth={1}
                  borderColor="#1A7431"
                  bgColor="#1A7431"
                  color='#fff'
                  fontSize='sm'
                  w="100%"
                  fontWeight='bold'
                  borderRadius='5px'
                  px='10px'
                  py='10px'
                  onClick={() => window.location.assign(`/dashboard/purchase-history#${type}`)}
                >
                  Saya sudah melakukan pembayaran
                </Button>
              </Flex>
            ) : paymentResult ? (
              <div className={styles.containerQR}>
                <p><strong>{paymentResult?.product.name}</strong></p>
                <p>Payment status: <strong>{paymentResult?.payment_result.status}</strong></p>
                <p>RP {paymentResult?.payment_result.charge_amount}</p>
              </div>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        borderBottomWidth={1}
        width="100%"
        direction="row"
        gap={10}
        pt={5}
        pb={5}
        pr={10}
        pl={10}
      >
        <Flex flex={1} justifyContent="center">
          <Flex display={{sm: 'none', md: 'flex'}} direction="column" gap={5}>
            {listPayment.map((type, index) => (
              <Flex
                padding={8}
                cursor="pointer"
                justifyContent="center"
                alignItems="center"
                borderWidth={1}
                borderRadius={10}
                borderColor={"#1A7431"}
                color={activeType === index ? "white" : "black"}
                bgColor={activeType === index ? "#1A7431" : "white"}
                onClick={() => handleSelectType(type, index)}
              >
                <Text>{type.name}</Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Divider orientation="vertical" />
        <Flex flex={1} justifyContent="center" display={{sm: 'none', md: 'flex'}}>
          {(activeType !== null && selectedType?.payment_methods) && (
            <Flex direction="column" gap={5}>
              {selectedType.payment_methods.map((method, index) => (
                <Flex
                padding={5}
                cursor="pointer"
                borderWidth={1}
                borderRadius={10}
                borderColor={"#1A7431"}
                color={activeMethod === index ? "white" : "black"}
                bgColor={activeMethod === index ? "#1A7431" : "white"}
                onClick={() => handleSelectMethod(method, index)}
                >
                  <img src={method.logo} alt="method" width={100}/>
                </Flex>
              ))}
              {(selectedMethod && !qrString) && (
                <Flex>
                  <Button
                    borderWidth={1}
                    borderColor="#1A7431"
                    bgColor="#1A7431"
                    color='#fff'
                    fontSize='sm'
                    w="100%"
                    fontWeight='bold'
                    borderRadius='5px'
                    px='10px'
                    py='10px'
                    onClick={() => handlePay()}
                  >
                    {"Lanjut bayar"}
                  </Button>
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
        <Divider orientation="vertical" />
        <Flex flex={1} justifyContent="center" display={{sm: 'none', md: 'flex'}}>
          {qrString ? (
            <Flex direction="column" gap={5}>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={qrString}
                viewBox={`0 0 256 256`}
              />
              <Text textAlign="center">Rp {new Intl.NumberFormat('id-ID').format(paymentResult?.payment_result.amount)}</Text>
              <Button
                borderWidth={1}
                borderColor="#1A7431"
                bgColor="#1A7431"
                color='#fff'
                fontSize='sm'
                w="100%"
                fontWeight='bold'
                borderRadius='5px'
                px='10px'
                py='10px'
                onClick={() => window.location.assign(`/dashboard/purchase-history#${type}`)}
              >
                I have made the payment
              </Button>
            </Flex>
          ) : paymentResult ? (
            <div className={styles.containerQR}>
              <p><strong>{paymentResult?.product.name}</strong></p>
              <p>Payment status: <strong>{paymentResult?.payment_result.status}</strong></p>
              <p>RP {paymentResult?.payment_result.charge_amount}</p>
            </div>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
    // <div>
    //   <div className={styles.containerStepTwo}>
    //     {qrString ? (
    //       <div className={styles.containerQR}>
    //         <p><strong>QRIS</strong></p>
    //         <QRCode
    //           size={256}
    //           style={{ height: "auto", maxWidth: "20%", width: "20%" }}
    //           value={qrString}
    //           viewBox={`0 0 256 256`}
    //         />
    //         <p>Rp {new Intl.NumberFormat('id-ID').format(paymentResult?.payment_result.amount)}</p>
    //         <div className={styles.containerButton}>
    //           <Button
    //             borderWidth={1}
    //             borderColor="#1A7431"
    //             bgColor="#1A7431"
    //             color='#fff'
    //             fontSize='sm'
    //             w="100%"
    //             fontWeight='bold'
    //             borderRadius='5px'
    //             px='10px'
    //             py='10px'
    //             onClick={() => window.location.assign('/dashboard/profile')}
    //           >
    //             I have made the payment
    //           </Button>
    //         </div>
    //       </div>
    //     ) : paymentResult ? (
    //       <div className={styles.containerQR}>
    //         <p><strong>{paymentResult?.product.name}</strong></p>
    //         <p>Payment status: <strong>{paymentResult?.payment_result.status}</strong></p>
    //         <p>RP {paymentResult?.payment_result.charge_amount}</p>
    //       </div>
    //     ) : (
    //       <div>
    //         <div className={styles.containerTopTwo}>
    //           <h4 className="title">Select Payment</h4>
    //           {/* <p className="content">Please check again the data you provided below and service you want to order. </p> */}
    //         </div>
    //         <div className={styles.containerCenter}>
    //           <div className={styles.containerListType}>
                // {listPayment.map((type, index) => (
                //   <div className={activeType === index ? "paymentActive" : "paymentType"} onClick={() => handleSelectType(type, index)}>
                //     <p>{type.name}</p>
                //   </div>
                // ))}
    //           </div>
    //         </div>
    //         <div className={styles.containerMethod}>
    //           {(activeType !== null && selectedType?.payment_methods) && (
    //             <div className={styles.containerListMethod}>
    //               <h5 className="block">Select Payment Method</h5>
    //               {selectedType.payment_methods.map((method, index) => (
    //                 <div className={activeMethod === index ? "paymentActive" : "paymentMethod"} onClick={() => handleSelectMethod(method, index)}>
    //                   <img src={method.logo} alt="method" width={100}/>
    //                 </div>
    //               ))}
    //             </div>
    //           )}
    //         </div>
    //         <div className={styles.containerMethod}>
    //           {(activeMethod !== null && selectedMethod?.code === "ID_OVO") && (
    //             <div className={styles.containerListMethod}>
    //               <h5 className="block-phone">Phone Number</h5>
    //                 <TextField
    //                   hiddenLabel
    //                   fullWidth
    //                   id="phoneNumber"
    //                   defaultValue={phoneNumber ?? ""}
    //                   placeholder="+62"
    //                   variant="outlined"
    //                   size="small"
    //                   type="number"
    //                   onChange={e => setPhoneNumber(e.target.value)}
    //                 />
    //             </div>
    //           )}
    //         </div>
    //         {selectedMethod && (
    //           <div className={styles.containerButton}>
    //             <Button
    //               borderWidth={1}
    //               borderColor="#1A7431"
    //               bgColor="#1A7431"
    //               color='#fff'
    //               fontSize='sm'
    //               w="100%"
    //               fontWeight='bold'
    //               borderRadius='5px'
    //               px='10px'
    //               py='10px'
    //               onClick={() => handlePay()}
    //             >
    //               {"Proceed to Payment >"}
    //             </Button>
    //           </div>
    //         )}
    //       </div>
    //     )}
    //   </div>
    // </div>
  );
}

export default PaymentMethod;
