export const columnsDataPersonaPurchaseHistory = [
  {
    Header: "DISPLAY NAME",
    accessor: "user.name",
  },
  {
    Header: "SERVICE",
    accessor: "product.name",
  },
  {
    Header: "ACTION",
    accessor: "product",
  },
  {
    Header: "TANGGAL BELI",
    accessor: "created_at",
  },
  {
    Header: "TANGGAL EXPIRED",
    accessor: "expired_at",
  },
];